import youreTheOne from '../content/images/books/Youre-The-One.png'
const upcomingBooks = [
  {
    id: 1,
    title: `You're the One`,
    cover: youreTheOne,
  },
];

export default upcomingBooks;
